import React, { useState } from "react";
import {
	Box,
	Button,
	Center,
	Container,
	Flex,
	Heading,
	HStack,
	Link,
	Stack,
	Text,
	useToast,
} from "@chakra-ui/react";
import {
	CountryContacts,
	Footer,
	Header,
	InputText,
	InputTextArea,
	LinksStrip,
	SEO,
} from "../components";
import { useForm } from "react-hook-form";
import { colors, contactsSchema } from "../constants";
import TwitterIcon from "../icons/twitter.svg";
import LinkedinIcon from "../icons/linkedin.svg";
import { motion } from "framer-motion";
import Axios from "axios";
import ContactsRed from "../images/contacts_red.png";
import ContactsGreen from "../images/contacts_green.png";
import ContactsBlue from "../images/contacts_blue.png";

interface IForm {
	name: string;
	email: string;
	title: string;
	text: string;
}

const MotionBox = motion(Box);

const ContactsPage: React.FC = () => {
	const toast = useToast();
	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm<IForm>({
		resolver: contactsSchema,
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onSubmit = (data: IForm) => {
		setIsLoading(true);
		Axios.post(process.env.API_URL + "api/web/contact-form", data)
			.then(result => {
				setIsLoading(false);
				toast({
					title: "Message sent",
					description: "We would read it as soon as possible",
					status: "success",
					duration: 3000,
				});
				reset({});
			})
			.catch(err => {
				setIsLoading(false);
				toast({
					title: "Failed",
					description: "Message has not been sent",
					status: "error",
					duration: 3000,
				});
			});
	};

	return (
		<Box
			bg="background"
			overflowX="hidden"
			position="relative"
			zIndex={10}
			display="flex"
			flexDirection="column"
			height="100vh"
		>
			<SEO
				title={"LegioSoft | Contact Us"}
				description={"Get in touch with us for expert enterprise solutions. Our team is ready to assist you with advanced cloud technology and tailored project support."}
				keywords={["contact us", "help", "opportunities"]}
			/>
			<Header />
			<Container
				maxW="container.xl"
				// height={{ base: "auto", md: "calc(100vh - 175px)" }}

				zIndex={10}
				position="inherit"
			>
				<LinksStrip active="Contacts" />
				<Stack
					align="baseline"
					direction={{ base: "column", md: "row" }}
					spacing={{ md: "50px", lg: "120px" }}
					overflow="hidden"
					marginBottom="100px"
				>
					<Box flex={1}>
						<Heading fontSize="40px" color="white">
							Contact us
						</Heading>
						<Text
							color="white"
							fontSize="20px"
							lineHeight="25px"
							fontFamily="Poppins"
							mt="20px"
							mb="70px"
						>
							Please describe your problem in this contact form
						</Text>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Flex flexDirection={{ base: "column", md: "row" }}>
								<InputText
									placeholder="Name"
									{...register("name")}
									errorMsg={errors.name?.message}
								/>
								<Box w="50px" />
								<InputText
									placeholder="Mail"
									{...register("email")}
									errorMsg={errors.email?.message}
								/>
							</Flex>
							<InputText placeholder="Title" {...register("title")} />
							<InputTextArea
								placeholder="Message"
								{...register("text")}
								errorMsg={errors.text?.message}
							/>
							<Button
								isLoading={isLoading}
								loadingText={"Submitting..."}
								variant="primary"
								type="submit"
								mt="20px"
								textTransform="uppercase"
							>
								Send message
							</Button>
						</form>
					</Box>
					<Box flex={1}>
						<HStack spacing="20px" mt={{ base: "50px", md: 0 }}>
							<Center
								w="39px"
								h="39px"
								bg={colors.twitterColor}
								borderRadius="50%"
								as={Link}
								href="https://twitter.com/legiosoft"
							>
								<TwitterIcon />
							</Center>
							<Center
								w="39px"
								h="39px"
								bg={colors.linkedInColor}
								borderRadius="50%"
								as={Link}
								href="https://www.linkedin.com/company/legio-soft"
							>
								<LinkedinIcon />
							</Center>
						</HStack>
						<CountryContacts
							countryName="Spain"
							address="Calle de les Garrigues 1, p. 11, Valencia, 46001"
						/>
					</Box>
				</Stack>
			</Container>
			<MotionBox
				initial={{ opacity: 0 }}
				whileInView={{ opacity: 1 }}
				viewport={{ once: true }}
				transition={{ duration: 2 }}
				w="1000px"
				h="1000px"
				backgroundImage={ContactsBlue}
				position="absolute"
				top="-100px"
				left="20vw"
				zIndex={0}
			/>
			<MotionBox
				initial={{ opacity: 0 }}
				whileInView={{ opacity: 1 }}
				viewport={{ once: true }}
				transition={{ duration: 2 }}
				w="1000px"
				h="1000px"
				backgroundImage={ContactsRed}
				position="absolute"
				borderRadius="50%"
				right="10vw"
				zIndex={0}
			/>
			<MotionBox
				initial={{ opacity: 0 }}
				whileInView={{ opacity: 1 }}
				viewport={{ once: true }}
				transition={{ duration: 2 }}
				w="1000px"
				h="1000px"
				backgroundImage={ContactsGreen}
				position="absolute"
				left="20vw"
				bottom="0"
				zIndex={0}
			/>
			<Box flex={1} />
			<Footer />
		</Box>
	);
};

export default ContactsPage;
